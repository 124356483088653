import { composeActionEvent, composeScreenEvent } from './utils'

const commonParams = {
    screenClass: 'viat',
    tipoOperativa: 'viat',
    tipologia: 'movilidad',
    subtipologia: 'transaccional',
    seccion1: 'mis productos',
    seccion2: 'viat',
}

// ------- STEP 1 ---------- //
export const showStep1View = composeScreenEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
})

export const startStep1Event = composeActionEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
    accionEvento: 'viat_inicio',
    etiquetaEvento: 'viat',
})

export const clickContinueButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
    accionEvento: 'clic_continuar',
    etiquetaEvento: 'viat',
})

export const applyPromoCodeEvent = (stepName) => composeActionEvent({
    ...commonParams,
    screenName: 'viat ' + stepName,
    seccion3: stepName,
    accionEvento: 'cod_promocional',
    etiquetaEvento: 'aplicar promocion',
})
